import Cookie from 'js-cookie';
import axios from 'axios';
import AES256 from 'aes-everywhere';

import router from '@/router';

import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

const loginMode = "";
const useDebug = true;

const APP_NAME = 'APAS Saúde';
const MAX_UPLOAD_SIZE_FILE_MB = 20;
const MAX_UPLOAD_SIZE_IMAGE_MB = 2;
const MAX_UPLOAD_SIZE_IMPORT_MB = 30;
const DEFAULT_IMAGE = 'src/media/img/default_image.jpg';

export default {
    loginMode,
    useDebug,
    APP_NAME,
    MAX_UPLOAD_SIZE_FILE_MB,
    MAX_UPLOAD_SIZE_IMAGE_MB,
    MAX_UPLOAD_SIZE_IMPORT_MB,
    DEFAULT_IMAGE,
    fnIgnore() { return false; },
    isArray(obj) { return (obj === null) ? false : ((typeof obj).toLowerCase() === "object"); },
    hasStr(str, find) { return str.toString().indexOf(find.toString()) !== -1; },
    isZero(str) {
        try {
            str *= 1;
            if (str === 0) { return true; }
        } catch (e) {
            this.fnIgnore();
        }
        return false;
    },
    async getImageDimensions(strBase64File) {
        return new Promise(function (resolved) {
            let imgCheck = new Image();
            imgCheck.onload = function () {
                resolved({ w: imgCheck.width, h: imgCheck.height });
            };
            imgCheck.src = strBase64File;
        })
    },
    async getBase64FromFile(file) {
        return new Promise(function (resolved) {
            let reader = new FileReader();
            reader.onload = function () {
                resolved(reader.result);
            };
            reader.readAsDataURL(file);
        })
    },
    isEmpty(str) {
        if (
            str === null ||
            str === false ||
            str === undefined || (
                this.isArray(str) &&
                str.length === 0
            )
        ) {
            return true;
        } else if (this.isArray(str) && str.length > 0) { return false; }

        try {
            str = str.toString().trim().toLowerCase();
            if (
                str === "" || str === "false" ||
                str === "null" || str === "undefined" ||
                str === "nan"
            ) { return true; }
        } catch (e) {
            this.fnIgnore();
        }

        return this.isZero(str);
    },
    isEmptyDecimal(str) {
        try { return this.isEmpty(parseFloat(str)); } catch (e) { this.fnIgnore(); }
        return this.isEmpty(str);
    },
    isEmptyInteger(str) {
        try { return this.isEmpty(parseInt(str)); } catch (e) { this.fnIgnore(); }
        return this.isEmpty(str);
    },
    callLinkExternalBrowser(link) {
        if (this.isEmpty(link)) { return false; }
        window.open(link, '_system', 'location=yes');
    },
    callLinkExternalAPIBrowser(link) {
        let newURL = process.env.VUE_APP_API_BASE_URL + link;
        if (!this.hasStr(newURL, "?")) {
            newURL += "?";
        } else {
            if (newURL.slice(-1) !== "&") {
                newURL += "&";
            }
        }
        newURL += process.env.VUE_APP_API_AUTH_KEY + "=" + process.env.VUE_APP_API_AUTH_VAL;

        this.callLinkExternalBrowser(newURL);
    },
    onlyNumbersAndComma(str) {
        if (str === undefined || str === null) return "";
        return str.toString().replace(/[^0-9,]/g, '').toString();
    },
    minifyText(str) {
        if (str === undefined || str === null) return "";
        str = str.toString();

        let newStr = str.substring(0, 100);
        if (newStr !== str) {
            newStr += "...";
        }

        return newStr;
    },
    encodeHTMLEntities(str) {
        const textArea = document.createElement('textarea');
        textArea.textContent = str;
        str = textArea.innerHTML;

        textArea.remove();
        return str;
    },
    decodeHTMLEntities(str) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        str = textArea.textContent;

        textArea.remove();
        return str;
    },
    onlyNumbers(str) {
        if (str === undefined || str === null) return "";
        return str.toString().replace(/[^0-9]/g, '').toString();
    },
    downloadFileB64(name, contents) {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        downloadLink.href = contents;
        downloadLink.target = '_self';
        downloadLink.download = name.replace(/[/\\?%*:|"<>]/g, '-');
        downloadLink.click();
    },
    generateGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    redirectPath(path, query = {}) {
        if (path.substring(0, 1) !== "/") { path = "/" + path; }
        router.push({ path: path, query: query });
    },
    base64UrlEncode(input) {
        if (this.isEmpty(input)) {
            return null;
        }
        return btoa(input).split("+").join(".").split("/").join("_").split("=").join("-");
    },
    base64UrlDecode(input) {
        if (this.isEmpty(input)) {
            return null;
        }
        return atob(input.split(".").join("+").split("_").join("/").split("-").join("="));
    },
    async copyToClipboard(str, messageAfterCopy = 'Copiado para a área de transferência!') {
        let clipboardValue = null;
        try {
            clipboardValue = navigator.clipboard.writeText(str);
            await this.presentToast(messageAfterCopy, 'info', 'top', 1000);
        } catch ($e) { clipboardValue = null; }

        return clipboardValue;
    },
    async checkToken(action = null) {
        let returnData = {};
        let utils = this;
        let uri = '/seguranca/seleciona-token';

        if (action == 'new') {
            uri = '/seguranca/gerar-token';
        }

        let newURL = process.env.VUE_APP_API_BASE_URL + uri;
        let method = 'post';

        let data = {
            'username': AES256.encrypt(process.env.VUE_APP_TOKEN_LOGIN, process.env.VUE_APP_AES),
            'password': AES256.encrypt(process.env.VUE_APP_TOKEN_PASSWORD, process.env.VUE_APP_AES)
        }

        let dataIsForm = (data instanceof FormData);

        try {
            await axios({
                method,
                url: newURL,
                data: (!dataIsForm ? JSON.stringify(data) : data)
            }).then(response => {
                utils.l("THEN ALL");
                utils.l(response);
                returnData = response.data.return;
            }).catch(function (err) {
                utils.l("CATCH AXIOS ALL");
                utils.l(err);

                let response = null;
                let isJSON = false;
                try {
                    response = JSON.parse(err?.request?.response);
                    isJSON = true;
                } catch (e) {
                    response = err?.request?.response;
                }

                if (isJSON) {
                    returnData = {
                        "status": response?.error?.code ?? 500,
                        "message": response?.error?.msg ?? 'Opa... Parece que há um problema aqui. Estamos verificando para que consiga continuar! ',
                    };

                    if (returnData.status.toString() === "401") {
                        utils.l(returnData.status)
                    }
                }
            });
        } catch (e) {
            utils.l("CATCH ALL");
            this.l(e);
        }

        if (!utils.isEmpty(returnData.dados)) {
            returnData = returnData.dados.token
        }

        newURL = "";
        utils = null;

        return returnData;
    },
    async callAPI(method, uri, data = null) {
        let utils = this;

        let token = await utils.checkToken();

        if (token == 1) {
            token = await utils.checkToken('new');
        }

        if (utils.isEmpty(token)) {
            returnData = {
                "status": 500,
                "message": 'Falha na obtenção do token. Contate um administrador.',
            };
        }

        this.l('callAPI is running...');

        this.l('callAPI data before:');
        this.l(data);

        let dataIsForm = (data instanceof FormData);
        if (!this.isEmpty(data) && !this.isEmpty(process.env.VUE_APP_AES)) {
            // colocar neste array as chaves que devem ir criptografadas para o backend
            const encryptKeys = ['username', 'password', 'birthday', 'email', 'identidadeAcesso', 'nomeSala'];
            encryptKeys.forEach(key => {
                if (!dataIsForm) {
                    if (!this.isEmpty(data[key])) {
                        data[key] = AES256.encrypt(data[key], process.env.VUE_APP_AES);
                    }
                } else {
                    if (data.has(key)) {
                        data.set(key, AES256.encrypt(data.get(key), process.env.VUE_APP_AES))
                    }
                }
            });
        }

        this.l('callAPI data after:');
        this.l(data);

        let returnData = {};

        let tentativa = 1;
        let limitTimeExec = new Date();
        limitTimeExec.setMinutes(limitTimeExec.getMinutes() + 2);

        let newURL = process.env.VUE_APP_API_BASE_URL + uri;

        //Tenta até 2 minutos de execução ou até 10 tentativas
        while (new Date() <= limitTimeExec && tentativa <= 10) {
            try {
                await axios({
                    method,
                    url: newURL,
                    data: (!dataIsForm ? JSON.stringify(data) : data),
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    utils.l("THEN ALL");
                    utils.l(response);
                    returnData = response.data.return;
                }).catch(function (err) {
                    utils.l("CATCH AXIOS ALL");
                    utils.l(err);

                    let response = null;
                    let isJSON = false;
                    try {
                        response = JSON.parse(err?.request?.response);
                        isJSON = true;
                    } catch (e) {
                        response = err?.request?.response;
                    }

                    if (isJSON) {
                        returnData = {
                            "status": response?.error?.code ?? 500,
                            "message": response?.error?.msg ?? 'Opa... Parece que há um problema aqui. Estamos verificando para que consiga continuar! ',
                        };

                        if (returnData.status.toString() === "401") {
                            tentativa = 10;
                        }
                    }
                });
            } catch (e) {
                utils.l("CATCH ALL");
                this.l(e);
            }

            this.l("callAPI - URL '" + newURL + "'; Params '" + (!dataIsForm ? JSON.stringify(data) : "FORMDATA") + "'; Method '" + method.toUpperCase() + "'; Tentativa '" + tentativa + "'; Retorno '" + JSON.stringify(returnData) + "'");
            if (this.isEmpty(this.getErrorAPI(returnData))) {
                break;
            }
            tentativa++;
        }
        newURL = "";
        utils = null;

        return returnData;
    },
    async callAPIMedico(method, uri, data = null) {
        return await this.callAPI(method, ("/medico" + uri), data);
    },
    async callLoginAPI(data) {
        if (this.loginMode === "fake") { data.debug = 'true'; }
        return await this.callAPIMedico('post', '/login', data);
    },
    agoraDate() {
        return new Date().toISOString().split('T')[0];
    },
    removeAppCookies() {
        const cookiesToRemove = [
            process.env.VUE_APP_IS_LOGGED_IN,
            process.env.VUE_APP_USER_DATA,
        ]

        cookiesToRemove.forEach(cookieToRemove => {
            Cookie.remove(String(cookieToRemove), { sameSite: 'strict' });
        });
    },
    async setAppCookies(cookiesToSet) {
        await cookiesToSet.forEach((cookieToSet) => {
            Cookie.set(
                cookieToSet.name,
                cookieToSet.value,
                { sameSite: 'strict' }
            )
        })
    },
    /**
     * Verifies a cookie by its name
     * @returns boolean
     * @param cookieName
     */
    hasCookieByName(cookieName) { return Cookie.get(cookieName) ? true : false },
    /**
     * Função genérica de log no console
     * @param thing
     */
    l(thing) { if (useDebug) { console.log(thing); } },
    dateToDateBr(dateToFormat, shortYear = false) {
        let newDate = dateToFormat.split("T").join(" ").split(" ");  // ex input: "2010-01-18"
        let dtArr = newDate[0].split("-");  // ex input: "2010-01-18"
        let day = parseInt(dtArr[2]);
        let month = parseInt(dtArr[1]);
        let year = dtArr[0];

        if (shortYear) { year = year.slice(2); }

        return (((day <= 9) ? ('0' + day) : day) + '/' + ((month <= 9) ? ('0' + month) : month) + '/' + year);
    },
    monthToMonthBr(dateToFormat) {
        let dtArr = dateToFormat.split("-");  // ex input: "2010-10"
        let month = parseInt(dtArr[1]);
        let year = parseInt(dtArr[0]);

        return (((month <= 9) ? ('0' + month) : month) + '/' + year);
    },
    valueParaMoedaReal(value) {
        return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    },
    datetimeToDateTimeBr(dateToFormat, showSeconds = false) {
        if (this.isEmpty(dateToFormat)) return '';
        return this.dateToDateBr(dateToFormat) + ' ' + this.dateToTime(dateToFormat, showSeconds);
    },
    dateToTime(dateToFormat, showSeconds = false) {
        let manipulation = dateToFormat.split("T").join(" ").split(" ");  // ex input: "2010-01-18 00:00:00"
        if (manipulation.length > 1) {
            manipulation = manipulation[1];
        } else {
            manipulation = manipulation[0];
        }

        let ret = "";
        let timeArr = manipulation.split(":");  // ex input: "00:00:00"
        let hour = parseInt(timeArr[0]);
        let minute = parseInt(timeArr[1]);
        let second = 0;
        if (!this.isEmpty(timeArr[2])) {
            second = parseInt(timeArr[2]);
        }

        ret += ((hour <= 9) ? ('0' + hour) : hour).toString();
        ret += (":" + ((minute <= 9) ? ('0' + minute) : minute).toString());
        if (showSeconds) {
            ret += (":" + ((second <= 9) ? ('0' + second) : second).toString());
        }

        return ret;
    },
    /**
     * Retorna a data e o horário de hoje no padrão 'YYYY-MM-DDTH:i:s'
     */
    datetimeNowString() {
        const now = new Date();
        let day = now.getDate();
        day = ((day <= 9) ? ('0' + day.toString()) : day);

        let month = now.getMonth() + 1;
        month = ((month <= 9) ? ('0' + month.toString()) : month);

        return `${now.getFullYear()}-${month}-${day}T${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
    },
    capitalize(textToCapitalize) { return (textToCapitalize.charAt(0).toUpperCase() + textToCapitalize.slice(1).toLowerCase()); },
    elShow(el) { el.style.display = 'unset' },
    elHide(el) { el.style.display = 'none'; },
    setImage(image) { return this.isEmpty(image) ? this.DEFAULT_IMAGE : image; },
    timestampToDateBR(timestamp) {
        const date = new Date(parseInt(timestamp));

        let day = date.getDate();
        day = ((day <= 9) ? ('0' + day.toString()) : day);

        let month = date.getMonth() + 1;
        month = ((month <= 9) ? ('0' + month.toString()) : month);

        return this.dateToDateBr(`${date.getFullYear()}-${month}-${day}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`)
    },

    /**
     * @param {string} message Mensagem a ser apresentada
     * @param {string} type Pode-se utilizar: success, info, warning, error, default
     * @param {string} position Pode=se utilizar: top, bottom, top-right, bottom-right, top-left, bottom-left
     * @param {number} duration Duração em milisegundos
     */
    presentToast(message, type = 'info', position = 'top', duration = 5000) {
        useToast().open({
            message: message,
            type: type,
            position: position,
            duration: duration,
        });
    },

    getErrorAPI(response) {
        if (this.isEmpty(response)) {
            return "Não há dados disponíveis.";
        }
        if (!this.isEmpty(response['status']) && response['status'] != 200 && !this.isEmpty(response['message'])) {
            return response['message'];
        }
        if (!this.isEmpty(response['code']) && response['code'] != 200 && !this.isEmpty(response['msg'])) {
            return response['msg'];
        }

        return null;
    }
};


