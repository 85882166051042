import utils from '@/helpers/utils';
let progressInterval = null;

export default {
    setIsLoggedIn(state) {
        state.isLoggedIn = (
            utils.hasCookieByName(String(process.env.VUE_APP_IS_LOGGED_IN))
        );
    },

    setIsLoggedOff(state) { state.isLoggedIn = 0; },

    toggleProgressBar(state, status) {
        state.isProgressBarVisible = status;

        if (progressInterval) {
            clearInterval(progressInterval);
        }

        if (status) {
            state.progressStep = 1;
            progressInterval = setInterval(() => {
                if (state.progressStep < 3) {
                    state.progressStep++;
                } else {
                    clearInterval(progressInterval);
                    state.isProgressBarVisible = false;
                    state.progressStep = 0;
                }
            }, 2000);
        } else {
            clearInterval(progressInterval);
            state.progressStep = 0;
        }
    },

    setUserData(state, userData) { state.userData = userData; }
}
